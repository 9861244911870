import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import testReducer from './test';
import authReducer from './auth';
import commonReducer from './common';
import companiesReducer from './companies';

export default combineReducers({
  test: testReducer,
  auth: authReducer,
  common: commonReducer,
  companies: companiesReducer,

  routing: routerReducer,
});
