import Client from 'services/api';
import * as types from './action-types';
import * as commonTypes from '../common/action-types';
import { push } from 'react-router-redux';

export const logout = () => (dispatch) => {
  Client.deleteToken();
  dispatch({ type: types.LOGOUT });
  dispatch(push('/login'));
};

export const requestCode = (email) => (dispatch) => {
  dispatch({ type: types.GET_AUTH_CODE_REQUEST });
  return Client.post('api/v1/auth/login', { email })
    .then((res) => {
      dispatch({ type: types.GET_AUTH_CODE_SUCCESST });
      return res;
    })
    .catch((res) => {
      dispatch({ type: types.GET_AUTH_CODE_FAILURE });
      dispatch({
        type: commonTypes.OPEN_SNACKBAR,
        payload: { type: 'error', message: res.data.message },
      });
      throw res;
    });
};
export const confirmCode = (data) => (dispatch) => {
  dispatch({ type: types.CONFIRM_AUTH_CODE_REQUEST });
  Client.post('api/v1/auth/confirm', data)
    .then((res) => {
      Client.setToken(res.accessToken, res.refreshToken);
      dispatch({ type: types.CONFIRM_AUTH_CODE_SUCCESS, payload: res });
    })
    .catch((res) => {
      dispatch({ type: types.CONFIRM_AUTH_CODE_FAILURE });
      dispatch({
        type: commonTypes.OPEN_SNACKBAR,
        payload: { type: 'error', message: res.data.message },
      });
    });
};

export const initAuth = () => (dispatch) => {
  const { refreshToken } = Client.getToken();
  if (Client.getToken().refreshToken) {
    dispatch({ type: types.UPDATE_TOKEN_REQUEST });
    Client.post('api/v1/auth/update', { refreshToken })
      .then((res) => {
        dispatch({ type: types.UPDATE_TOKEN_SUCCESS, payload: res });
        Client.setToken(res.accessToken, res.refreshToken);
        dispatch({ type: types.CHECK_TOKEN_REQUEST });
        Client.get('api/v1/auth/check').then((res) => {
          dispatch({ type: types.CHECK_TOKEN_SUCCESS, payload: res });
        });
      })
      .catch((res) => {
        dispatch({ type: types.UPDATE_TOKEN_FAILURE });
        dispatch(push('/login'));
      });
  } else {
    dispatch({ type: types.UPDATE_TOKEN_FAILURE });
    dispatch(push('/login'));
  }
};
