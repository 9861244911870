import * as types from './action-types';

const initialState = {
  isSnackBarOpen: false,
  description: { type: 'error', message: '' },
  spinner: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.OPEN_SNACKBAR:
      return { ...state, isSnackBarOpen: true, description: payload };

    case types.CLOSE_SNACKBAR:
      return { ...state, isSnackBarOpen: false };

    default:
      return { ...state };
  }
};
