import React, { useState } from 'react';
import locale from '../../../localization';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    outline: 'none',
  },
  container: {
    border: 'none',
    outline: 'none',
  },
  paper: {
    padding: '0px 0px 20px 0px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    boxShadow: '0 0 10px rgba(80,80,80,0.4)',
    borderRadius: 10,
    position: 'relative',
  },
  button: {
    marginTop: 20,
    width: '90%',
    textTransform: 'none',
    fontSize: 16,
  },
  textField: {
    minHeight: 68,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    padding: theme.spacing(1) + 12,
    lineHeight: '24px',
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    fontWeight: 500,
  },
  icon: {
    color: '#FFF',
  },
  content: {
    padding: theme.spacing(1) + 12,
  },
}));

const Delete = ({
  open = false,
  onClose,
  deleteCallbackParams,
  onDelete,
  title,
  button_lable = false,
}) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const onSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    e.stopPropagation();
    onDelete(deleteCallbackParams)
      .then(() => {
        onClose();
        setDisabled(false);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Container maxWidth="sm" className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.title} align="center">
            {title}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ) : null}
          <div className={classes.content}>
            <Typography variant="h6" align="center" className={classes.label}>
              {locale.delete_field_label}
            </Typography>
            <Grid container>
              <Grid item xs={6} align="center">
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={onClose}
                >
                  {locale.cancel}
                </Button>
              </Grid>
              <Grid item xs={6} align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={onSubmit}
                  disabled={disabled}
                >
                  {locale.delete_button_label}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Container>
    </Modal>
  );
};

export default Delete;
