import * as types from './action-types';

const initialState = {
  autorized: false,
  autorization: true,
  user: {},
  endToken: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGOUT: {
      return { ...state, autorized: false, user: {}, endToken: null };
    }
    case types.GET_AUTH_CODE_REQUEST: {
      return { ...state };
    }
    case types.GET_AUTH_CODE_SUCCESST: {
      return { ...state };
    }
    case types.GET_AUTH_CODE_FAILURE: {
      return { ...state };
    }
    case types.CONFIRM_AUTH_CODE_REQUEST: {
      return { ...state };
    }
    case types.CONFIRM_AUTH_CODE_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        autorized: true,
        autorization: false,
      };
    }
    case types.CONFIRM_AUTH_CODE_FAILURE: {
      return { ...state };
    }
    case types.CHECK_TOKEN_REQUEST: {
      return { ...state };
    }
    case types.CHECK_TOKEN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        autorized: true,
        autorization: false,
      };
    }
    case types.CHECK_TOKEN_FAILURE: {
      return { ...state, autorization: false };
    }
    case types.UPDATE_TOKEN_REQUEST: {
      return { ...state };
    }
    case types.UPDATE_TOKEN_SUCCESS: {
      return { ...state };
    }
    case types.UPDATE_TOKEN_FAILURE: {
      return {
        ...state,
        autorized: false,
        autorization: false,
        user: {},
        endToken: null,
      };
    }

    default:
      return { ...state };
  }
}
