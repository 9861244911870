import ru from './ru';
import en from './en';

const LANG_EN = 'en';
const LANG_RU = 'ru';

let APP_LANGUAGE = LANG_EN;

let locale = en;
if (window.navigator.language.slice(0, 2) === 'ru') {
  locale = ru;
  APP_LANGUAGE = LANG_RU;
}

export default locale;
export { APP_LANGUAGE, LANG_RU, LANG_EN };
