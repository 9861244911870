import React from 'react';
export default {
  lang: 'en',
  yes: 'yes',
  no: 'no',
  ok: 'ok',
  delete_field_label: 'Are you sure you want to delete this item?',
  date_picker_title: 'Pick date range',
  login_email_field_error: 'Enter valid email',
  default_field_label: 'Default',
  empty_field: 'none',
  success: 'Success',
  created: 'Created',
  updated: 'Updated',
  deleted: 'Deleted',
  noUploadFileText: 'Select upload file',
  noStatisticText: 'We have no statistics for the selected parameters.',
  companies_tab: 'Companies',
  logout_tab: 'Logout',
  delete_button_label: 'Delete',
  cancel: 'Cancel',
  rows_per_page: 'rows per page',
  displayed_rows: ({ from, to, count }) =>
    `${from}-${to} of ${count !== -1 ? count : 'more than ' + to} `,

  //login page
  login_title: 'Sign In',
  login_button: 'Next',
  login_refresh_code: 'Get code again',
  login_submit_code: 'Submit',

  //noContent page
  notfound_text: 'Page Does Not Exist.',

  //Companies
  companies_title: 'Companies',
  edit_company_title: 'Edit company',
  create_company_title: 'Create company',
  company_created_field_label: 'Created',
  company_action_field_label: 'Action',
  company_create_button_label: 'Create',
  company_edit_button_label: 'Edit',
  company_ban_button_label: 'Ban company',
  company_unban_button_label: 'Unban company',
  company_isCloud_switcher_label: 'isCloud',
  company_license_label: 'License',
  company_rows_per_page: 'rows per page',
  company_displayed_rows: ({ from, to, count }) =>
    `${from}-${to} of ${count !== -1 ? count : 'more than ' + to} `,

  company_name_field_label: 'Name',
  company_domain_field_label: 'Domains',
  company_email_field_label: 'Admin Email',
  company_place_limit_field_label: 'Places limit',
  company_place_url_field_label: 'Api url',
  company_place_cloud_field_label: 'Our server',
  company_place_contact_name_field_label: 'Contact name',
  company_place_contact_number_field_label: 'Contact mobile',
  company_places_count: 'Places count',
  company_last_check_date: 'Last check date',
  company_name_field_error: 'Enter name',
  company_domain_field_error: 'Enter valid domain',
  company_email_field_error: 'Enter valid email',
  company_place_limit_field_error: 'Enter a valid places limit',
  company_apiurl_field_error: 'Enter api url',
  company_contactname_field_error: 'Enter contact name',
  company_contactphone_field_error: 'Enter valid contact mobile',
  company_activate_license_date: 'Start date',
  company_end_license_date: 'End date',
  invaliDateLabel: 'Invalid date format',
  delete_company_title: 'Delete company',
  max_date_message: 'Date should not be after maximal date',
  min_date_message: 'Date should not be before minimal date',
};
