import React from 'react';
import locale from 'localization/index';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '80vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const AuthSpinner = ({ open = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={60} style={{ color: '#66CCFF' }} />
      <Typography variant="h4">{locale.check_auth_text}</Typography>
    </div>
  );
};
export default AuthSpinner;
