import * as types from './action-types';

const initialState = {
  companies: [],
  company: {},
  page: 0,
  pageCount: 0,
  totalCount: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_COMPANIES_REQUEST:
      return { ...state };

    case types.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: payload.companies,
        page: payload.pagination.page,
        pageCount: payload.pagination.pageCount,
        totalCount: payload.pagination.totalCount,
      };

    case types.GET_COMPANIES_FAILURE:
      return { ...state };

    case types.CREATE_COMPANY_REQUEST:
      return { ...state };

    case types.CREATE_COMPANY_SUCCESS:
      return { ...state };

    case types.CREATE_COMPANY_FAILURE:
      return { ...state };

    case types.UPDATE_COMPANY_REQUEST:
      return { ...state };

    case types.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map((item) =>
          item._id === payload.company._id
            ? { ...item, ...payload.company }
            : item,
        ),
      };
    case types.UPDATE_COMPANY_FAILURE:
      return { ...state };
    case types.BAN_ACTION_COMPANY_REQUEST:
      return { ...state };

    case types.BAN_ACTION_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map((item) =>
          item._id === payload.company._id
            ? { ...item, ...payload.company }
            : item,
        ),
      };
    case types.BAN_ACTION_COMPANY_FAILURE:
      return { ...state };

    case types.GET_COMPANY_REQUEST:
      return { ...state };

    case types.GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
      };

    case types.GET_COMPANY_FAILURE:
      return { ...state };

    case types.DELETE_COMPANY_REQUEST:
      return { ...state };

    case types.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter((v) => v._id !== payload),
        totalCount: state.totalCount - 1,
      };

    case types.DELETE_COMPANY_FAILURE:
      return { ...state };
    default:
      return { ...state };
  }
};
