import locale from '../../../localization';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '1440px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: {
    marginBottom: 80,
    fontSize: 50,
    color: '#303c42',
    fontWeight: 700,
  },
}));

const NoContentPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span className={classes.text}>{locale.notfound_text}</span>
      <img alt="noimage" src="/NoContent.png" />
    </div>
  );
};

export default NoContentPage;
