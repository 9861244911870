import React from 'react';
export default {
  lang: 'ru',
  yes: 'да',
  no: 'нет',
  ok: 'подтвердить',
  delete_field_label: 'Вы уверены что хотите удалить этот элемент?',
  date_picker_title: 'Выберите промежуток времени',
  login_email_field_error: 'Введите действительный адрес почты',
  default_field_label: 'По умолчанию',
  empty_field: 'пусто',
  success: 'Успешно!',
  created: 'Создано!',
  updated: 'Обновлено!',
  deleted: 'Удалено!',
  noUploadFileText: 'Выберите загружаемый файл',
  noStatisticText: 'У нас нет статистики для выбранных параметров ',
  companies_tab: 'Компании',
  logout_tab: 'Выйти',
  delete_button_label: 'Удалить',
  cancel: 'Отмена',
  rows_per_page: 'Строк',
  displayed_rows: ({ from, to, count }) =>
    `${from}-${to} из ${count !== -1 ? count : 'более ' + to} `,

  //login page
  login_title: 'Войти',
  login_button: 'Далее',
  login_refresh_code: 'Получить код снова',
  login_submit_code: 'Подтвердить',

  //noContent page
  notfound_text: 'Страница не существует',

  //Companies
  companies_title: 'Компании',
  edit_company_title: 'Редактирование компании',
  create_company_title: 'Создание компании',
  company_created_field_label: 'Создана',
  company_action_field_label: 'Действие',
  company_create_button_label: 'Создать',
  company_edit_button_label: 'Редактировать',
  company_ban_button_label: 'Заблокировать',
  company_unban_button_label: 'Разблокировать',
  company_isCloud_switcher_label: 'Наш сервер',
  company_license_label: 'Лицензия',
  company_rows_per_page: 'Строк',
  company_displayed_rows: ({ from, to, count }) =>
    `${from}-${to} из ${count !== -1 ? count : 'более ' + to} `,
  company_name_field_label: 'Название',
  company_domain_field_label: 'Домены',
  company_email_field_label: 'Адрес почты администратора',
  company_place_limit_field_label: 'Ограничение по местам',
  company_place_url_field_label: 'Адрес api сервера',
  company_place_cloud_field_label: 'Наш сервер',
  company_place_contact_name_field_label: 'Имя сотрудника',
  company_place_contact_number_field_label: 'Телефон сотрудника',
  company_places_count: 'Текущее количество мест',
  company_last_check_date: 'Дата последней проверки',
  company_name_field_error: 'Введите название',
  company_domain_field_error: 'Введите действительный домен',
  company_email_field_error: 'Введите действительный адрес почты',
  company_place_limit_field_error: 'Введите ограничение по местам',
  company_apiurl_field_error: 'Введите адрес api сервера',
  company_contactname_field_error: 'Введите имя контакта',
  company_contactphone_field_error: 'Введите верный номер телефона сотрудника',
  company_activate_license_date: 'Дата активации лицензии',
  company_end_license_date: 'Дата окончания лицензии',
  invaliDateLabel: 'Неверный формат даты',
  delete_company_title: 'Удалить компанию',
  max_date_message: 'Дата не может быть позже максимальной',
  min_date_message: 'Дата не может быть раньше минимальной',
};
