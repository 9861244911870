import React, { useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './styles/theme';
import './App.css';

import { initAuth } from './redux/auth/actions';
import NoContentPage from 'components/pages/NoContentPage';
import Login from './components/pages/Login';
import NavBar from 'components/common/Navbar';
import AuthSpinner from 'components/common/AuthSpinner';
import Snackbar from 'components/common/Snackbar';
import Companies from 'components/pages/Companies';
import Company from 'components/pages/Company';

const App = ({ autorized, autorization, initAuth }) => {
  useEffect(() => {
    initAuth();
  }, [initAuth]);

  useEffect(() => {
    const root = document.getElementById('root');
    // document.onscroll = evt => {
    //   if (!!root.getAttribute('aria-hidden')) {
    //     window.scrollTo(0, 0);
    //   }
    // };
  }, []);
  return (
    <div className="App">
      {!autorization ? (
        <ThemeProvider theme={theme}>
          <>
            <Switch>
              <Route exact path="/login" component={Login} />
              {autorized && (
                <>
                  <NavBar />
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/companies" />
                    </Route>
                    <Route exact path="/companies/:id" component={Company} />
                    <Route exact path="/companies" component={Companies} />
                  </Switch>
                </>
              )}
            </Switch>
            <Snackbar />
          </>
        </ThemeProvider>
      ) : (
        <Route component={AuthSpinner} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  autorized: state.auth.autorized,
  autorization: state.auth.autorization,
});
const mapDispatchToProps = { initAuth };

export default connect(mapStateToProps, mapDispatchToProps)(App);
