import locale from '../../../localization';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { logout } from 'redux/auth/actions';

import './index.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  AppBar: {
    color: '#fff',
    width: '100%',
    minWidth: 1440,
  },
  active: {
    background:
      'linear-gradient(0deg, rgba(142, 142, 147, 0.15), rgba(142, 142, 147, 0.15)), #66CCFF',
    boxShadow:
      '0px 3px 2px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05)',
    borderRadius: 5,
  },
  button: {
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 600,
    fontSize: 16,
  },
  logout_button: {
    textTransform: 'none',

    fontWeight: 600,
    fontSize: 16,
  },
  headWrapper: {
    boxSizing: 'border-box',
    width: 1440,
    margin: 'auto',
    padding: '0px 20px',
  },
}));
const NavBar = ({ autorized, logout }) => {
  const classes = useStyles();

  const handleLogout = (e) => logout();

  return (
    <div className={classnames(classes.root, 'navbar')}>
      <AppBar position="static" className={classes.AppBar}>
        <Toolbar className={classes.headWrapper}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            direction="row"
            spacing={2}
            style={{ margin: 0 }}
          >
            <Grid item xs={11} style={{ display: 'flex' }}>
              <Button
                color="inherit"
                component={NavLink}
                exact
                to="/companies"
                activeClassName={classes.active}
                className={classes.button}
              >
                {locale.companies_tab}
              </Button>
            </Grid>
            {autorized && (
              <Grid item xs={1}>
                <Button
                  color="inherit"
                  className={classes.logout_button}
                  onClick={handleLogout}
                >
                  {locale.logout_tab}
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  autorized: state.auth.autorized,
});

const mapDispatchToProps = { logout };

export default memo(connect(mapStateToProps, mapDispatchToProps)(NavBar));
