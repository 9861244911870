export const LOGOUT = 'LOGOUT';

export const GET_AUTH_CODE_REQUEST = 'GET_AUTH_CODE_REQUEST';
export const GET_AUTH_CODE_SUCCESST = 'GET_AUTH_CODE_SUCCESS';
export const GET_AUTH_CODE_FAILURE = 'GET_AUTH_CODE_FAILURE';

export const CONFIRM_AUTH_CODE_REQUEST = 'CONFIRM_AUTH_CODE_REQUEST';
export const CONFIRM_AUTH_CODE_SUCCESS = 'CONFIRM_AUTH_CODE_SUCCESS';
export const CONFIRM_AUTH_CODE_FAILURE = 'CONFIRM_AUTH_CODE_FAILURE';

export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';

export const UPDATE_TOKEN_REQUEST = 'UPDATE_TOKEN_REQUEST';
export const UPDATE_TOKEN_SUCCESS = 'UPDATE_TOKEN_SUCCESS';
export const UPDATE_TOKEN_FAILURE = 'UPDATE_TOKEN_FAILURE';
